import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Box from '@material-ui/core/Box';

import UserSideLinks from '../UserSideLinks';
import { useAccountStyles } from './accountStyles';
import CustomerProfile from './CustomerProfile';
import CustomerProfileForm from './CustomerProfileForm';

const CustomerAccountPage = () => {
  const classes = useAccountStyles();
  const { path } = useRouteMatch();

  return (
    <Box className={classes.container}>
      <UserSideLinks />
      <Switch>
        <Route path={`${path}/edit`} component={CustomerProfileForm} />
        <Route path={path} component={CustomerProfile} />
      </Switch>
    </Box>
  );
};

export default CustomerAccountPage;
