import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { updateProfile } from '../user-service';
import useLoggedInUser from './useLoggedInUser';

export default function useProfileUpdate() {
  const user = useLoggedInUser();

  const dispatch = useDispatch();

  return useMutation((profileData: any) => updateProfile(user?.id as string, profileData), {
    retry: 1,
    onSuccess: (data, profileFields) => {
      dispatch({
        type: 'set_system_state',
        payload: {
          snackBarIsOpen: true,
          snackBarMessage: 'Your profile was updated successfully!',
          snackBarType: 'success',
        },
      });

      dispatch({
        type: 'set_system_user_details',
        payload: profileFields,
      });
    },
    onError: (error: any) => {
      let err = 'An unknown error occured';

      if (error.response) {
        if (error.response.status === 401) return;

        err = error.response.data?.error?.message;
      }

      dispatch({
        type: 'set_system_state',
        payload: {
          snackBarIsOpen: true,
          snackBarMessage: err,
          snackBarType: 'error',
        },
      });
    },
  });
}
