import React, { MouseEventHandler, useState, useMemo } from 'react';
import { Box, Button, IconButton, Typography } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import CartIcon from '@material-ui/icons/ShoppingCartOutlined';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import { Product } from '../home/types';
import { colors } from '../../theme/defaultTheme';
import { formatToCurrency } from '../../utils/helpers';
import ProductDetails from './ProductDetails';
import {
  useAddFavorite,
  useFavoritesQuery,
  useRemoveFavoriteForHome,
} from '../user-specific/hooks/favorites-hooks';
import { useIsLoggedIn } from '../user-specific/hooks/useLoggedInUser';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSystemState } from '../../store/system/actions';
import { addToCart } from '../../store/product/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      cursor: 'pointer',
    },
    imageBox: {
      backgroundColor: colors.gray,
      width: '100%',

      '& > figure': {
        boxSizing: 'border-box',
        width: '100%',
        aspectRatio: '1 / 1',
        margin: 0,
        padding: 12,
        backgroundColor: '#F5F5F5',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    favoriteBtn: {
      position: 'absolute',
      top: 12,
      right: 12,
      color: colors.gray,
      backgroundColor: colors.white,
      width: 36,
      height: 36,
    },
    h1: {
      fontSize: 13,
      color: colors.gray,
      marginTop: 8,
    },
    image: {
      maxWidth: '100%',
      maxHeight: '100%',
      display: 'block',
    },
    name: {
      color: 'inherit',
      fontSize: 'inherit',
      textDecoration: 'none',
      marginBottom: 12,
      display: 'block',

      '& > h2': {
        fontWeight: 'bold',
      },

      '&:hover': {
        color: colors.primary,
      },
    },
    price: {
      fontWeight: 'bold',
      marginBottom: 12,
      display: 'inline-block',
    },
    discount: {
      display: 'inline-block',
      color: colors.gray,
      fontSize: '13px',
      marginLeft: 8,
      textDecoration: 'line-through',
    },
    button: {
      color: colors.white,
      backgroundColor: colors.primary,
      borderRadius: 0,
      padding: '13px 16px',
      textTransform: 'capitalize',
      fontSize: 14,
      width: '100%',
      marginTop: 'auto',

      '&:hover': {
        backgroundColor: colors.primary,
        opacity: 0.9,
      },
    },
  })
);

function ProductCard({ product }: { product: Product }) {
  const classes = useStyles();

  const [detailsModalShown, setDetailsModalShown] = useState(false);
  const isLoggedIn = useIsLoggedIn();
  const history = useHistory();
  const favorites = useFavoritesQuery();
  const addFavorite = useAddFavorite();
  const removeFavorite = useRemoveFavoriteForHome();

  const dispatch = useDispatch();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const favs = favorites.data?.map((f) => f.id) ?? [];

  const isFavorite = favs.includes(product.id);

  const handleFavoriteClick: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.stopPropagation();

    console.log(searchParams.get('q'));

    dispatch(setSystemState({ redirectTo: `/search?q=${searchParams.get('q')}` }));

    // if not logged in, redirect to login page
    if (!isLoggedIn) {
      history.push('/login');
      return;
    }

    // perform mutation
    if (!isFavorite) {
      await addFavorite.mutateAsync({ productId: product.id, vendorId: product.vendorId });
      return;
    }

    await removeFavorite.mutateAsync({ favoriteId: product.id, favoriteName: product.name });
  };

  const handleAddToCart = () => {
    const { id, vendorId, productSkuId } = product;
    if (!isLoggedIn) {
      history.push('/login');
    } else {
      dispatch(addToCart(id, productSkuId, vendorId));
    }
  };

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.imageBox} onClick={() => setDetailsModalShown(true)}>
          <figure>
            <img className={classes.image} src={product.image.path} alt={product.name} />
          </figure>
        </Box>
        <IconButton
          className={classes.favoriteBtn}
          aria-label='favorite this product'
          onClick={handleFavoriteClick}
        >
          {isFavorite ? <FavoriteIcon htmlColor='red' /> : <FavoriteBorderIcon />}
        </IconButton>
        <Box flex={1} display='flex' flexDirection='column'>
          <Typography className={classes.h1}>{product.h1}</Typography>
          <Box className={classes.name} onClick={() => setDetailsModalShown(true)}>
            <Typography component='h2'>{product.name}</Typography>
          </Box>
          <Box>
            <Typography className={classes.price}>{formatToCurrency(product.price)}</Typography>
            {Boolean(product.discountPrice) && (
              <Typography className={classes.discount}>
                {formatToCurrency(product.discountPrice ?? 0)}
              </Typography>
            )}
          </Box>
          <Button
            className={classes.button}
            variant='contained'
            disableElevation
            startIcon={<CartIcon />}
            onClick={handleAddToCart}
          >
            Add to Cart
          </Button>
        </Box>
      </Box>
      {detailsModalShown && (
        <ProductDetails product={product} onClose={() => setDetailsModalShown(false)} />
      )}
    </>
  );
}

export default ProductCard;
