import { InputButton } from '../../common/CommonComponents';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ProductCard from '../../common/ProductCard';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import {
  FeaturedCategoryContainer,
  FeaturedCategoryDescriptionContainer,
  FeaturedCategoryItemsContainer,
  FeaturedCategoryItemsControllerContainer,
  FeaturedCategoryControllerButton,
  FeaturedCategoryItemsButtonContainer,
  FeaturedCategoryItemsCardContainer,
} from './HomeComponents';
import Box from '@material-ui/core/Box';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import map from 'lodash/map';
import LoadingIndicator from '../../common/LoadingIndicator';
import { publicAxiosClient } from '../../../utils/apiPublicClient';
import { Product } from '../types';
import { AppState } from '../../../store';
import { Route, useHistory } from 'react-router-dom';
import { addToCart } from '../../../store/product/actions';
import { BannerContainer } from './HomeStyle';
import useAtBreakpoint from '../hooks/useAtBreakpoint';
import useLocale from '../hooks/useLocale';
import axios from 'axios';
import CarouselItemProductDetail from '../../common/CarouselItemProductDetail';
const API_URL = process.env.REACT_APP_API_URL;
const ACCESS_TOKEN = process.env.REACT_APP_API_TOKEN;
const HomeUserBestSellers = ({country}:{country:string}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLarge = useAtBreakpoint('lg');
  const user = useSelector((state: AppState) => state.auth.isLoggedIn);
  const userToken = useSelector((state: AppState) => state.system.session?.token);
  const userId = useSelector((state: AppState) => state.system.session?.userDetails?.id);
  

  const {
    data: bestSellers,
    status: bestSellersStatus,
    error: bestSellersError,
  } = useQuery<Product[]>(
    'bestSellers',
    async () =>
      (
        await publicAxiosClient.get<Product[]>(
          `v1/products/public/best-seller?countryCode=${country}`
        )
      ).data
  );

  //if(!user) return null;
  if (bestSellersStatus === 'loading') return <LoadingIndicator />;
  if (!bestSellers) return null;
  if (bestSellersError) return <div>Something Went Wrong</div>;
  const addToCartHandler = (id: string, productSkuId: string, vendorId: string) => {
    if (!user) {
      history.push('/login');
    } else {
      dispatch(addToCart(id, productSkuId, vendorId));
    }
  };
  const productClickHandler = (id: string) => {
    history.push(`/home/products/${id}`);
  };
  return (
    <>
      <FeaturedCategoryContainer className='featured-category'>
        <FeaturedCategoryItemsContainer>
          <FeaturedCategoryItemsControllerContainer>
            <Box fontSize='20px' width='100%'>
              Best Sellers
            </Box>
          </FeaturedCategoryItemsControllerContainer>
          <FeaturedCategoryItemsCardContainer>
            <BannerContainer>
              <Box className='headers-carousel'>
                <Carousel
                  showIndicators={false}
                  showStatus={false}
                  showThumbs={false}
                  emulateTouch={true}
                  infiniteLoop={false}
                  centerSlidePercentage={isLarge ? 100 / 5 : 100}
                  centerMode={true}
                >
                  {map(bestSellers, (i) => (
                    <ProductCard
                      {...i}
                      id={i.id}
                      key={i.id}
                      vendorId={i.vendorId}
                      h1={i.h1}
                      productSkuId={i.productSkuId}
                      onProductClick={() => {
                        productClickHandler(i.id);
                      }}
                      addToCartClick={() => addToCartHandler(i.id, i.productSkuId, i.vendorId)}
                      isFavorite={false}
                    />
                  ))}
                </Carousel>
              </Box>
            </BannerContainer>
          </FeaturedCategoryItemsCardContainer>
        </FeaturedCategoryItemsContainer>
      </FeaturedCategoryContainer>
      <Route path='/home/products/:id' component={CarouselItemProductDetail} />
    </>
  );
};

export default HomeUserBestSellers;
