import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

export const useAddressStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    headerText: {
      fontSize: 15,
      fontWeight: 600,
    },

    form: {
      [theme.breakpoints.down('md')]: {
        width: '100%',

        '& .title': {
          width: '100%',
          boxSizing: 'border-box',
        },
      },
    },

    formContainer: {
      width: '50%',

      [theme.breakpoints.down('md')]: {
        width: '100%',
        boxSizing: 'border-box',

        '& > div': {
          width: '100%',
        },
      },
    },

    formButtons: {
      [theme.breakpoints.down('md')]: {
        '& button': {
          flex: 1,
          minWidth: 'auto',
        },
      },
    },
  })
);
