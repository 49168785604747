import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';

import { appColors } from '../../../utils/ui-utils';
import FormInput from '../../common/FormInput';
import { useAccountStyles } from './accountStyles';
import FormButton from '../../common/FormButton';
import useLoggedInUser from '../hooks/useLoggedInUser';
import { CustomerProfileInputs, useProfileForm } from './my-account-utils';
import useProfileUpdate from '../hooks/useProfileUpdate';

const CustomerProfileForm = () => {
  const classes = useAccountStyles();
  const navigate = useHistory();
  const user = useLoggedInUser();

  const form = useProfileForm();

  const profileMutation = useProfileUpdate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  const goBackToProfile = () => {
    navigate.replace('/me/account');
  };

  const onError = (err: typeof errors) => {
    console.log(err);
  };

  const onSubmit = async (profileFields: CustomerProfileInputs) => {
    // mutate here
    await profileMutation.mutateAsync(profileFields);
  };

  return (
    <Box flex={1} width='100%'>
      <Box
        bgcolor={appColors.lightGray}
        padding='16px 20px'
        mb={3}
        className='title'
        display='flex'
        justifyContent='space-between'
      >
        <Typography component='h1' style={{ fontSize: 16, fontWeight: 600 }}>
          Edit Profile
        </Typography>
      </Box>
      <Box
        component='form'
        onSubmit={handleSubmit(onSubmit, onError)}
        style={{
          pointerEvents: profileMutation.isLoading ? 'none' : 'initial',
        }}
      >
        <Box className={classes.profileContainer}>
          <FormInput
            label='First Name'
            placeholder='Please enter your first name'
            {...register('firstName')}
            error={!!errors?.firstName}
            helperText={errors?.firstName?.message}
          />
          <FormInput
            label='Last Name'
            placeholder='Please enter your last name'
            {...register('lastName')}
            error={!!errors?.lastName}
            helperText={errors?.lastName?.message}
          />
          <FormInput
            label='Mobile'
            placeholder={user?.countryCode === 'PH' ? 'XXXXXXXXXX' : 'XXXXXXXX'}
            {...register('mobileNumber')}
            error={!!errors?.mobileNumber}
            helperText={errors?.mobileNumber?.message}
            inputMode='numeric'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>{user?.countryNumber || '+63'}</InputAdornment>
              ),
            }}
          />
          <FormInput
            label='Landline'
            placeholder='XXXXXXX'
            {...register('landlineNumber')}
            error={!!errors?.landlineNumber}
            helperText={errors?.landlineNumber?.message}
            inputMode='numeric'
            InputProps={{
              startAdornment: <InputAdornment position='start'>(043)</InputAdornment>,
            }}
          />
          <FormInput
            label='Email'
            type='email'
            placeholder='your_email@domain.com'
            {...register('email')}
            error={!!errors?.email}
            helperText={errors?.email?.message}
          />
        </Box>

        <Box
          display='flex'
          flex={1}
          justifyContent='flex-end'
          style={{ gap: 20 }}
          mt={4}
          className={classes.formButtons}
        >
          <FormButton onClick={goBackToProfile} className='outline'>
            Cancel
          </FormButton>
          <FormButton type='submit'>
            {profileMutation.isLoading ? 'Saving...' : 'Save Changes'}
          </FormButton>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerProfileForm;
