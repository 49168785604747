import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import UserSideLinks from './UserSideLinks';
import CustomerOrders from './CustomerOrders';
import CustomerOrderDetail from './CustomerOrderDetail';
import { useAccountStyles } from './my-account/accountStyles';

const UserOrderPage = () => {
  const classes = useAccountStyles();
  const { path } = useRouteMatch();

  return (
    <Box className={classes.container}>
      <UserSideLinks />
      <Switch>
        <Route path={`${path}/:vendorName/:orderId`}>
          <CustomerOrderDetail />
        </Route>
        <Route path={path}>
          <CustomerOrders />
        </Route>
      </Switch>
    </Box>
  );
};

export default UserOrderPage;
