import React from 'react';

import Box from '@material-ui/core/Box';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import LoadingIndicator from '../../common/LoadingIndicator';

import { useFavoritesQuery } from '../hooks/favorites-hooks';
import NoDataView from '../../common/NoDataView';
import FormButton from '../../common/FormButton';
import FavoriteItemCard from './FavoriteItemCard';
import { Route, useHistory } from 'react-router-dom';
import FavoriteRemovePrompt from './FavoriteRemovePrompt';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 1fr)',
      gridAutoFlow: 'row',
      gap: 24,
      marginBottom: 30,

      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },

      [theme.breakpoints.between('sm', 'md')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },

      [theme.breakpoints.between('md', 'lg')]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
    },
  })
);

function FavoritesPage() {
  const classes = useStyles();
  const favorites = useFavoritesQuery();
  const history = useHistory();

  if (favorites.isLoading) return <LoadingIndicator />;

  const isEmpty = !favorites.data || favorites.data.length === 0;

  const goToHomePage = () => history.push('/home');

  if (isEmpty)
    return (
      <NoDataView message='You have no favorites yet. Browse products now'>
        <FormButton onClick={goToHomePage}>Browse Products</FormButton>
      </NoDataView>
    );

  return (
    <Box className={classes.container}>
      {favorites.data?.map((favorite) => (
        <FavoriteItemCard key={favorite.id} favorite={favorite} />
      ))}
      <Route path='/me/favorites/delete/:id' component={FavoriteRemovePrompt} />
    </Box>
  );
}

export default FavoritesPage;
