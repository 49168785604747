import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { colors } from '../../../theme/defaultTheme';
import { CustomerAddress } from './types';
import useLoggedInUser from '../hooks/useLoggedInUser';

export const customerAddressSchema = yup
  .object({
    addressLine: yup.string().required('Address is required').trim(),
    country: yup.string().required('Country is required'),
    province: yup.string().required('Province is required'),
    city: yup.string().required('City is required'),
    postalCode: yup.string().required('Postal code is required'),
    tag: yup.string().oneOf(['Home', 'Office']).default('Home'),
    isDefaultBillingAddress: yup.boolean().default(false),
    isDefaultShippingAddress: yup.boolean().default(false),
  })
  .required();

export type CustomerAddressInputs = yup.InferType<typeof customerAddressSchema>;

const defaultValues: CustomerAddressInputs = {
  addressLine: '',
  country: '',
  province: '',
  city: '',
  postalCode: '',
  tag: 'Home',
  isDefaultBillingAddress: false,
  isDefaultShippingAddress: false,
};

export const useAddressForm = (options?: { initialValues?: CustomerAddressInputs }) => {
  const form = useForm({
    defaultValues,
    resolver: yupResolver(customerAddressSchema),
  });

  const { setValue, watch } = form;

  const province = watch('province');

  useEffect(() => {
    if (!province) {
      setValue('city', '');
    }
  }, [province, setValue]);

  useEffect(() => {
    if (!options?.initialValues) return;

    const {
      addressLine,
      city,
      country,
      province,
      postalCode,
      tag,
      isDefaultBillingAddress,
      isDefaultShippingAddress,
    } = options.initialValues;

    setValue('addressLine', addressLine);
    setValue('city', city);
    setValue('country', country);
    setValue('province', province);
    setValue('postalCode', postalCode);
    setValue('tag', tag);
    setValue('isDefaultBillingAddress', isDefaultBillingAddress);
    setValue('isDefaultShippingAddress', isDefaultShippingAddress);
  }, [options?.initialValues, setValue]);

  return form;
};

export const addressColorMap = {
  Home: {
    text: colors.primary,
    bg: colors.primaryLight,
    border: colors.primaryLight,
  },
  Office: {
    text: colors.primary,
    bg: colors.white,
    border: colors.primary,
  },
};

export const constructAddress = (address: CustomerAddress) => {
  const { addressLine, province, city, country, postalCode } = address;

  return `${addressLine}, ${city}, ${province}, ${country}, ${postalCode}`;
};

// For Profile
export const customerProfileSchema = yup.object({
  firstName: yup.string().required('First name is required').trim(),
  lastName: yup.string().required('Last name is required').trim(),
  mobileNumber: yup.string().required('Mobile number is required').trim(),
  landlineNumber: yup
    .string()
    .matches(/^\d+$/, {
      excludeEmptyString: true,
      message: 'Must be a number only',
    })
    .max(7, 'Should be a maximum of 7 digits')
    .trim(),
  email: yup.string().email('Provide a valid email').required('Email is required').trim(),
});

export type CustomerProfileInputs = yup.InferType<typeof customerProfileSchema>;

export const useProfileForm = () => {
  const user = useLoggedInUser();

  const form = useForm<CustomerProfileInputs>({
    defaultValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      mobileNumber: user?.mobileNumber || '',
      landlineNumber: user?.landlineNumber || '',
      email: user?.email || '',
    },
    resolver: yupResolver(customerProfileSchema),
  });

  return form;
};
