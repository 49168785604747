import { useState } from 'react';

import { Box, Button, IconButton, Typography } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CartIcon from '@material-ui/icons/ShoppingCart';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import Product from '../../product/Product';
import ProductDetails from '../../search/ProductDetails';

import FormButton from '../../common/FormButton';
import useLoggedInUser from '../hooks/useLoggedInUser';
import { formatToCurrency } from '../../../utils/helpers';

import { FavoriteItem } from './types';
import { useHistory } from 'react-router-dom';
import { colors } from '../../../theme/defaultTheme';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../../store/product/actions';
import FavoriteDetails from './FavoriteDetails';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      cursor: 'pointer',
    },
    imageBox: {
      backgroundColor: colors.gray,
      width: '100%',

      '& > figure': {
        boxSizing: 'border-box',
        width: '100%',
        aspectRatio: '1 / 1',
        margin: 0,
        padding: 12,
        backgroundColor: '#F5F5F5',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    favoriteBtn: {
      position: 'absolute',
      top: 12,
      right: 12,
      color: colors.gray,
      backgroundColor: colors.white,
      width: 36,
      height: 36,
    },
    h1: {
      fontSize: 13,
      color: colors.gray,
      marginTop: 8,
    },
    image: {
      maxWidth: '100%',
      maxHeight: '100%',
      display: 'block',
    },
    name: {
      color: 'inherit',
      fontSize: 'inherit',
      textDecoration: 'none',
      marginBottom: 12,
      display: 'block',

      '& > h2': {
        fontWeight: 'bold',
      },

      '&:hover': {
        color: colors.primary,
      },
    },
    price: {
      fontWeight: 'bold',
      marginBottom: 12,
      display: 'inline-block',
    },
    discount: {
      display: 'inline-block',
      color: colors.gray,
      fontSize: '13px',
      marginLeft: 8,
      textDecoration: 'line-through',
    },
    button: {
      color: colors.white,
      backgroundColor: colors.primary,
      borderRadius: 0,
      padding: '13px 16px',
      textTransform: 'capitalize',
      fontSize: 14,
      width: '100%',
      marginTop: 'auto',

      '&:hover': {
        backgroundColor: colors.primary,
        opacity: 0.9,
      },
    },
  })
);

function FavoriteItemCard({ favorite }: { favorite: FavoriteItem }) {
  const classes = useStyles();
  const history = useHistory();
  const user = useLoggedInUser();

  const dispatch = useDispatch();

  const [detailsModalShown, setDetailsModalShown] = useState(false);

  const isSG = user?.countryCode === 'SG';

  const openDeletePrompt = () => history.push(`/me/favorites/delete/${favorite.id}`);

  const handleAddToCart = () => {
    const { id, vendorId, productSkuId } = favorite;
    if (!user) {
      history.push('/login');
    } else {
      dispatch(addToCart(id, productSkuId, vendorId));
    }
  };

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.imageBox} onClick={() => setDetailsModalShown(true)}>
          <figure>
            <img className={classes.image} src={favorite.image.path} alt={favorite.name} />
          </figure>
        </Box>
        <IconButton
          className={classes.favoriteBtn}
          aria-label='favorite this product'
          onClick={openDeletePrompt}
        >
          <FavoriteIcon htmlColor='red' />
        </IconButton>
        <Box flex={1} display='flex' flexDirection='column'>
          <Typography className={classes.h1}>{favorite.h1}</Typography>
          <Box className={classes.name} onClick={() => setDetailsModalShown(true)}>
            <Typography component='h2'>{favorite.name}</Typography>
          </Box>
          <Box>
            <Typography className={classes.price}>{formatToCurrency(favorite.price)}</Typography>
            {Boolean(favorite.discountPrice) && (
              <Typography className={classes.discount}>
                {formatToCurrency(favorite.discountPrice ?? 0)}
              </Typography>
            )}
          </Box>
          <Button
            className={classes.button}
            variant='contained'
            disableElevation
            startIcon={<CartIcon />}
            onClick={handleAddToCart}
          >
            Add to Cart
          </Button>
        </Box>
      </Box>
      {detailsModalShown && (
          <FavoriteDetails
          favorite={favorite}
          onClose={() => setDetailsModalShown(false)}
          onAddToCart={handleAddToCart}
        />      
      )}
    </>
  );
}

export default FavoriteItemCard;
