import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useGetCustomerAddress } from '../hooks/useGetCustomerAddress';
import LoadingIndicator from '../../common/LoadingIndicator';
import NoDataView from '../../common/NoDataView';
import CustomerAddressItem from './CustomerAddressItem';
import { appColors } from '../../../utils/ui-utils';
import FormButton from '../../common/FormButton';
import { useHistory } from 'react-router-dom';
import { useAddressStyles } from './addressStyles';
import AddressCard from './AddressCard';

const CustomerAddresList = () => {
  const { data: addresses, isLoading } = useGetCustomerAddress();

  const history = useHistory();

  const classes = useAddressStyles();

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  if (isLoading) return <LoadingIndicator />;

  if (!addresses) return <NoDataView message="You don't have an address yet. Add one now" />;

  const goToAddressForm = () => history.push('/me/address/add');

  return (
    <Box flex={1} display='flex' flexDirection='column' width='100%'>
      <Box
        bgcolor={appColors.lightGray}
        padding='16px 20px'
        mb={2}
        mt={-1}
        display='grid'
        gridTemplateColumns='350px 120px auto'
        alignItems='center'
        className={classes.header}
      >
        <Typography className={classes.headerText}>Address</Typography>
        <Typography className={classes.headerText}>Tag</Typography>
        <Typography className={classes.headerText} style={{ marginLeft: 'auto' }}>
          Action
        </Typography>
      </Box>
      <Box maxHeight={500} overflow='auto'>
        {addresses.map((address) =>
          isLargeScreen ? (
            <CustomerAddressItem key={address.id} address={address} />
          ) : (
            <AddressCard key={address.id} address={address} />
          )
        )}
      </Box>
      <FormButton
        onClick={goToAddressForm}
        startIcon={<AddIcon />}
        style={{ marginLeft: 'auto', marginTop: 16 }}
      >
        Add New Address
      </FormButton>
    </Box>
  );
};

export default CustomerAddresList;
