import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import UserTopLinks from './UserTopLinks';
import UserPages from './UserPages';
import Layout from '../common/Layout';
import { useSelector } from 'react-redux';
import { getAuthState } from '../../store/auth/actions';
import { Redirect } from 'react-router-dom';
import UserTopMobileTabs from './UserTopMobileTabs';

const UserSpecificPage = () => {
  const isLoggedIn = useSelector(getAuthState);

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  if (!isLoggedIn) return <Redirect to='/login' />;

  return (
    <Layout>
      {isLargeScreen ? <UserTopLinks /> : <UserTopMobileTabs />}
      <UserPages />
    </Layout>
  );
};

export default UserSpecificPage;
