import { useState } from 'react';
import { ListItem, ListItemText, Collapse, Box } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { Category } from '../../store/system/types';

interface CategoryItemProps {
  category: Category;
  onToggleDrawer: (close :boolean) => void;
  onCategorySelect: (h1: string) => void;
}

interface H2ItemProps {
  categoryH2: Category['h2'][number];
  onToggleDrawer: (close :boolean) => void;
  onCategorySelect: (h1: string) => void;
}

interface H3ItemProps {
  categoryH3: string;
  onToggleDrawer: (close :boolean) => void;
  onCategorySelect: (h1: string) => void;
}

const H3Item = ({ categoryH3, onToggleDrawer, onCategorySelect }: H3ItemProps) => {
  const h3HandleClick = () => {
    onToggleDrawer(false)
    onCategorySelect(categoryH3)
  };

  return (
    <>
      <ListItem button onClick={h3HandleClick}>
        <ListItemText primary={categoryH3} />
      </ListItem>
    </>
  );
};

const H2Item = ({ categoryH2, onToggleDrawer, onCategorySelect }: H2ItemProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => setIsExpanded((old) => !old);

  const h2HandleClick = () => {
    if (categoryH2.h3) {
      toggleExpanded();
    }
    else {
      onToggleDrawer(false)
      onCategorySelect(categoryH2.name)
    }
  };

  return (
    <>
      <ListItem button onClick={h2HandleClick}>
        <ListItemText primary={categoryH2.name} />
        {categoryH2.h3 && <ArrowDropDownIcon />}
      </ListItem>
      {categoryH2.h3 && (
        <Collapse in={isExpanded}>
          <Box ml={2}>
            {categoryH2.h3.map((h3) => (
              <H3Item key={h3} categoryH3={h3} onToggleDrawer={onToggleDrawer} onCategorySelect={onCategorySelect} />
            ))}
          </Box>
        </Collapse>
      )}
    </>
  );
};

const CategoryItem = ({ category, onToggleDrawer, onCategorySelect }: CategoryItemProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => setIsExpanded((old) => !old);

  const handleClick = () => {
    if (category.h2) {
      toggleExpanded();
    }
    else {
      onToggleDrawer(false)
      onCategorySelect(category.name)
    }
  };

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemText primary={category.name} />
        {category.h2 && <ArrowDropDownIcon />}
      </ListItem>
      {category.h2 && (
        <Collapse in={isExpanded}>
          <Box ml={2}>
            {category.h2.map((h2) => (
              <H2Item key={h2.name} categoryH2={h2} onToggleDrawer={onToggleDrawer} onCategorySelect={onCategorySelect} />
            ))}
          </Box>
        </Collapse>
      )}
    </>
  );
};

export default CategoryItem;
