import { 
    CartAction,
    CartStateInput, 
    RESET_CART_STATE, 
    SET_CART_STATE 
} from "./types";
import { SET_SYSTEM_STATE } from '../system/types';
import { AppThunk } from '../';

// Utils
import axios, { AxiosRequestConfig } from 'axios';
import find from 'lodash/find';
import filter from 'lodash/filter';
import { parse } from "path";

const API_URL = process.env.REACT_APP_API_URL;

export const setCartState = (input: CartStateInput) : CartAction => {
    return {
        type: SET_CART_STATE,
        payload: input
    }
}

export const fetchCartItems = () : AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_CART_STATE,
            payload: { cartLoading: false }
        })
        try {
            const res = await axios.get(`${API_URL}/bag/${getState().system.session?.userDetails.id}`);
            console.log(res.data)
            if (res.status === 200) {
                let bagData: Array<any> = [];
                for (let i in res.data) {
                    // vendor block
                    const vendorBlock = res.data[i];
                    for (let j in vendorBlock.bagItems) {
                        // products block
                        const bagContent = vendorBlock.bagItems[j];
                        bagData.push({
                            id: bagContent.id,
                            name: bagContent.name,
                            selected: bagContent.isOutOfStock ? true : false,
                            quantity: bagContent.qty,
                            price: bagContent.price ? bagContent.price : 0,
                            discountPrice: bagContent.discountPrice ? bagContent.discountPrice : 0,
                            image: bagContent.image ? bagContent.image : '',
                            color: bagContent.color ? bagContent.color : '',
                            size: bagContent.size ? bagContent.size : '',
                            vendorId: res.data[i].vendorId,
                            vendorName: res.data[i].vendorName,
                            isOutOfStock: bagContent.isOutOfStock,
                            productId: bagContent.productId,
                            productSkuId: bagContent.productSkuId,
                            skuNumber: bagContent.skuNumber,
                            displayId: bagContent.displayId,
                            h1: bagContent.h1,
                            h2: bagContent?.h2,
                            h3: bagContent?.h3,
                            brand: bagContent.brand,
                            description: bagContent.description,
                            material: bagContent.material,
                            type: bagContent.type,
                            marketStock: bagContent.marketStock
                        })
                    }
                }
                dispatch({
                    type: SET_CART_STATE,
                    payload: {
                        cartItems: bagData,
                        checkOutItems: [],
                        cartSummaryDiscount: 0,
                        cartSummarySubTotal: 0,
                        cartSummaryPoNumber: ''
                    }
                })
            }
        } catch (e) {
            console.log(e.response)
            dispatch({
                type: SET_SYSTEM_STATE,
                payload: {
                    snackBarIsOpen: true,
                    snackBarMessage: e.toString(),
                    snackBarType: 'error'
                }
            })
        } finally {
            dispatch({
                type: SET_CART_STATE,
                payload: { cartLoading: false }
            })
        }
    }
}

export const deleteCartItem = (bagId: string) : AppThunk => {
    return async (dispatch, getState) => {
        try {
            const delRes = await axios.delete(`${API_URL}/bag/${bagId}`);
            if (delRes.status === 204) {
                const { cartItems, checkOutItems } = getState().cart;
                dispatch({
                    type: SET_CART_STATE,
                    payload: {
                        cartItems: filter(cartItems, (i) => i.id !== bagId),
                        checkOutItems: filter(checkOutItems, (i) => i.id !== bagId)
                    }
                })
                dispatch({
                    type: SET_SYSTEM_STATE,
                    payload: { 
                        snackBarIsOpen: true,
                        snackBarMessage: 'Product successfully deleted',
                        snackBarType: 'success',
                        systemDialogOpen: false 
                    }
                })
            }
        } catch (e) {
            console.log(e);
            dispatch({
                type: SET_SYSTEM_STATE,
                payload: {
                    snackBarIsOpen: true,
                    snackBarMessage: e.toString(),
                    snackBarType: 'error'
                }
            })
        }
    }
}

export const deleteAllCartItems = () : AppThunk => {
    return async (dispatch, getState) => {
        try {
            const { cartItems } = getState().cart;
            const promises : Array<Promise<AxiosRequestConfig>> = [];

            cartItems.forEach(item => {
                promises.push(axios.delete(`${API_URL}/bag/${item.id}`))
            });

            if (promises) {
                try {
                    await Promise.all(promises).then(res => {
                        dispatch({
                            type: SET_CART_STATE,
                            payload: {
                                cartItems: [],
                                checkOutItems: [],
                            }
                        })
                        dispatch({
                            type: SET_SYSTEM_STATE,
                            payload: { 
                                snackBarIsOpen: true,
                                snackBarMessage: 'Cart items are successfully deleted',
                                snackBarType: 'success',
                                systemDialogOpen: false 
                            }
                        })
                    })
                } catch (e) {
                    console.log(e.toString());
                }
            }
        } catch (e) {
            console.log(e);
            dispatch({
                type: SET_SYSTEM_STATE,
                payload: {
                    snackBarIsOpen: true,
                    snackBarMessage: e.toString(),
                    snackBarType: 'error'
                }
            })
        }
    }
}

export const updateCartItemQuantity = (bagId: string, value: string) : AppThunk => {
    
    return async (dispatch, getState) => {
        
        try {
           
            dispatch({
                type: SET_CART_STATE,
                payload: { cartLoading: false }
            }); 
            // if(parseInt(value) > 50){
            //     dispatch({
            //         type: SET_SYSTEM_STATE,
            //         payload: {
            //             snackBarIsOpen: true,
            //             snackBarMessage: 'Sorry you can only purchase allowable quantity of this product',
            //             snackBarType: 'error'
            //         }
            //     }) 
            //     const body = {
            //         id: bagId,
            //         data: {
            //             qty: 50
            //         }
            //     }
            //     const upRes = await axios.put(`${API_URL}/bag`, body);
            //     if (upRes.status === 204) {
            //         dispatch({
            //             type: SET_CART_STATE,
            //             payload: { cartLoading: false }
            //         })
            //         dispatch(fetchCartItems())
            //     }
            // }else{
                const body = {
                    id: bagId,
                    data: {
                        qty: !value ? 0 : parseInt(value)
                    }
                }
                const upRes = await axios.put(`${API_URL}/bag`, body);
                if (upRes.status === 204) {
                    dispatch({
                        type: SET_CART_STATE,
                        payload: { cartLoading: false }
                    })
                    dispatch(fetchCartItems())
                }
            // }

        } catch (e) {
            console.log(e.response);
            dispatch({
                type: SET_SYSTEM_STATE,
                payload: {
                    snackBarIsOpen: true,
                    snackBarMessage: e.toString(),
                    snackBarType: 'error'
                }
            })
        }
    }
}

export const getCustomerAddresses = () : AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_CART_STATE,
            payload: {
                checkOutLoading: true
            }
        })
        try {
            const res = await axios.get(`${API_URL}/user/address/getAddress/${getState().system.session?.userDetails.id}`);
            const { status, data } = res;
            if (status === 200) {
                dispatch({
                    type: SET_CART_STATE,
                    payload: {
                        checkOutAddresses: filter(data, (i) => i.isDeleted === false),
                        checkOutShippingAddress: find(data, { id: getState().system.session?.userDetails.shippingAddressId }),
                        checkOutBillingAddress: find(data, { id: getState().system.session?.userDetails.billingAddressId })
                    }
                })
            }
        } catch (e) {
            console.log(e);
            dispatch({
                type: SET_SYSTEM_STATE,
                payload: {
                    snackBarIsOpen: true,
                    snackBarMessage: e.toString(),
                    snackBarType: 'error'
                }
            })
        } finally {
            dispatch({
                type: SET_CART_STATE,
                payload: {
                    checkOutLoading: false
                }
            })
        }
    }
}

export const resetCartState = () : CartAction => {
    return {
        type: RESET_CART_STATE,
        payload: null
    }
}