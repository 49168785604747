import { useLocation } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import UserLink from './UserLink';

const UserTopLinks = () => {
  const { pathname } = useLocation();

  return (
    <Box py={4} display='flex' alignItems='center' justifyContent='center' gridColumnGap={48}>
      <UserLink to='/me/quotations'>Quotations</UserLink>
      <UserLink to='/me/favorites'>Favorites</UserLink>
      <UserLink to='/me/followed-vendor'>Followed Vendor</UserLink>
      <UserLink to='/me/wishlists'>Wishlists</UserLink>
      <UserLink to='/me/orders' className={pathname.includes('track-order') ? 'with-active' : ''}>
        Orders
      </UserLink>
      <UserLink to='/me/account' className={pathname.includes('address') ? 'with-active' : ''}>
        My Account
      </UserLink>
    </Box>
  );
};

export default UserTopLinks;
