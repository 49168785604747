import { ChangeEvent, CSSProperties } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const tabContainerStyles: CSSProperties = {
  marginBottom: 12,
};

const tabStyles: CSSProperties = {
  textTransform: 'capitalize',
  fontSize: 14,
};

const tabIndicatorStyles: CSSProperties = {
  backgroundColor: 'transparent',
};

const links = [
  '/me/quotations',
  '/me/favorites',
  '/me/followed-vendor',
  '/me/wishlists',
  '/me/orders',
  '/me/account',
];

const UserTopMobileTabs = () => {
  const { pathname } = useLocation();
  const navigate = useHistory();

  const tabValue = links.indexOf(pathname);

  const handleChange = (e: ChangeEvent<{}>, tabIndex: number) => {
    navigate.push(links[tabIndex]);
  };

  return (
    <Tabs
      value={tabValue}
      onChange={handleChange}
      textColor='primary'
      variant='scrollable'
      scrollButtons='auto'
      aria-label='user menu'
      style={tabContainerStyles}
      TabIndicatorProps={{
        style: tabIndicatorStyles,
      }}
    >
      <Tab label='Quotations' style={tabStyles} />
      <Tab label='Favorites' style={tabStyles} />
      <Tab label='Followed Vendors' style={tabStyles} />
      <Tab label='Wishlists' style={tabStyles} />
      <Tab label='Orders' style={tabStyles} />
      <Tab label='My Account' style={tabStyles} />
    </Tabs>
  );
};

export default UserTopMobileTabs;
