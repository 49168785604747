import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { colors } from '../../../theme/defaultTheme';
import { appColors } from '../../../utils/ui-utils';

export const useAccountStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'flex-start',
      gridColumnGap: 32,

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    profileEditLink: {
      color: colors.primary,
      fontWeight: 'bold',
    },
    profileContainer: {
      border: `1px solid ${appColors.lightGray}`,
      padding: '32px 20px',
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: 20,

      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'unset',
        gap: 8,
      },
    },
    profileDetail: {
      display: 'flex',
      flexDirection: 'column',
      gap: 12,
    },
    profileDetailLabel: {
      color: appColors.grayText,
    },
    profileDetailValue: {
      fontSize: 15,
    },
    formButtons: {
      [theme.breakpoints.down('md')]: {
        '& button': {
          flex: 1,
          minWidth: 'auto',
        },
      },
    },
  })
);
