import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { appColors } from '../../../utils/ui-utils';
import { useAccountStyles } from './accountStyles';
import useLoggedInUser from '../hooks/useLoggedInUser';

interface ProfileDetailProps {
  label: string;
  value?: string;
}

const ProfileDetail = ({ label, value }: ProfileDetailProps) => {
  const classes = useAccountStyles();

  return (
    <Box className={classes.profileDetail}>
      <Typography className={classes.profileDetailLabel}>{label}</Typography>
      <Typography className={classes.profileDetailValue}>{value ? value : 'N/A'}</Typography>
    </Box>
  );
};

function CustomerProfile() {
  const classes = useAccountStyles();

  const user = useLoggedInUser();

  const fullName = `${user?.firstName} ${user?.lastName}` || '';

  return (
    <Box flex={1} width='100%'>
      <Box
        bgcolor={appColors.lightGray}
        padding='16px 20px'
        mb={3}
        className='title'
        display='flex'
        justifyContent='space-between'
      >
        <Typography component='h1' style={{ fontSize: 16, fontWeight: 600 }}>
          My Profile
        </Typography>
        <Link to='/me/account/edit' className={classes.profileEditLink}>
          Edit
        </Link>
      </Box>
      <Box className={classes.profileContainer}>
        <ProfileDetail label='Full Name' value={fullName} />
        <ProfileDetail label='Mobile' value={user?.mobileNumber} />
        <ProfileDetail label='Landline' value={user?.landlineNumber} />
        <ProfileDetail label='Email' value={user?.email} />
      </Box>
    </Box>
  );
}

export default CustomerProfile;
