import { InputButton } from '../../common/CommonComponents';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ProductCard from '../../common/ProductCard';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import {
  FeaturedCategoryContainer,
  FeaturedCategoryDescriptionContainer,
  FeaturedCategoryItemsContainer,
  FeaturedCategoryItemsControllerContainer,
  FeaturedCategoryControllerButton,
  FeaturedCategoryItemsButtonContainer,
  FeaturedCategoryItemsCardContainer,
} from './HomeComponents';
import Box from '@material-ui/core/Box';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import map from 'lodash/map';
import LoadingIndicator from '../../common/LoadingIndicator';
import { AppState } from '../../../store';
import { Route, useHistory } from 'react-router-dom';
import { addToCart } from '../../../store/product/actions';
import { BannerContainer } from './HomeStyle';
import useAtBreakpoint from '../hooks/useAtBreakpoint';
import useLocale from '../hooks/useLocale';
import axios from 'axios';
import CarouselItemProductDetail from '../../common/CarouselItemProductDetail';
const API_URL = process.env.REACT_APP_API_URL;

interface Item {
  dateCreated: string;
  discountPrice: number;
  h1: string;
  image: {
    name: string;
    path: string;
    size: number;
    type: string;
  };
  isFavorite: boolean;
  marketStock: number;
  name: string;
  price: number;
  productId: string;
  vendorId: string;
  skuId: string;
}

interface IRecom {
  hasNextPage: boolean;
  results: Item[];
}

const HomeUserRecommended = ({country}:{country:string}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLarge = useAtBreakpoint('lg');
  const user = useSelector((state: AppState) => state.auth.isLoggedIn);
  const userToken = useSelector((state: AppState) => state.system.session?.token);
  const userId = useSelector((state: AppState) => state.system.session?.userDetails?.id);

  const userAxiosClient = axios.create({
    baseURL: `${API_URL}`,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const {
    data: recommended,
    status: recommendedStatus,
    error: recommendedError,
  } = useQuery<IRecom>(
    'recommended',
    async () => (await userAxiosClient.get<IRecom>(`v1/users/${userId}/recommended?page=1`)).data
  );

  if (!user) return null;
  if (recommendedStatus === 'loading') return <LoadingIndicator />;
  if (recommended?.results.length === 0) return null;
  if (recommendedError && recommendedError) return <div>Something Went Wrong</div>;
  const addToCartHandler = (id: string, productSkuId: string, vendorId: string) => {
    console.log(id);
    console.log(productSkuId);
    console.log(vendorId);
    if (!user) {
      history.push('/login');
    } else {
      dispatch(addToCart(id, productSkuId, vendorId));
    }
  };
  const productClickHandler = (id: string) => {
    history.push(`/home/products/${id}`);
  };
  return (
    <>
      <FeaturedCategoryContainer className='featured-category'>
        <FeaturedCategoryItemsContainer>
          <FeaturedCategoryItemsControllerContainer>
            <Box fontSize='20px' width='100%'>
              Recommended For You
            </Box>
          </FeaturedCategoryItemsControllerContainer>
          <FeaturedCategoryItemsCardContainer>
            <BannerContainer>
              <Box className='headers-carousel'>
                <Carousel
                  showIndicators={false}
                  showStatus={false}
                  showThumbs={false}
                  emulateTouch={true}
                  infiniteLoop={false}
                  centerSlidePercentage={isLarge ? 100 / 5 : 100}
                  centerMode={true}
                >
                  {map(recommended?.results, (i) => (
                    <ProductCard
                      {...i}
                      id={i.productId}
                      key={i.productId}
                      vendorId={i.vendorId}
                      h1={i.h1}
                      productSkuId={i.name}
                      onProductClick={() => {
                        productClickHandler(i.productId);
                      }}
                      addToCartClick={() => addToCartHandler(i.productId, i.skuId, i.vendorId)}
                      isFavorite={false}
                    />
                  ))}
                </Carousel>
              </Box>
            </BannerContainer>
          </FeaturedCategoryItemsCardContainer>
        </FeaturedCategoryItemsContainer>
      </FeaturedCategoryContainer>
      <Route path='/home/products/:id' component={CarouselItemProductDetail} />
    </>
  );
};

export default HomeUserRecommended;
