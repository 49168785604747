import { useRouteMatch, Switch, Route } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import UserSideLinks from '../UserSideLinks';
import CustomerAddresList from './CustomerAddresList';
import CustomerAddressForm from './CustomerAddressForm';
import CustomerAddressEditForm from './CustomerAddressEditForm';
import { useAccountStyles } from './accountStyles';

const CustomerAddressPage = () => {
  const classes = useAccountStyles();
  const { path } = useRouteMatch();

  return (
    <Box className={classes.container}>
      <UserSideLinks />
      <Switch>
        <Route path={`${path}/edit/:addressId`} component={CustomerAddressEditForm} />
        <Route path={`${path}/add`} component={CustomerAddressForm} />
        <Route path={path} component={CustomerAddresList} />
      </Switch>
    </Box>
  );
};

export default CustomerAddressPage;
