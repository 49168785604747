import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { RiHome4Fill, RiBuilding4Fill } from 'react-icons/ri';

import { CustomerAddress } from './types';
import { appColors } from '../../../utils/ui-utils';
import useLoggedInUser from '../hooks/useLoggedInUser';
import { colors } from '../../../theme/defaultTheme';
import { addressColorMap, constructAddress } from './my-account-utils';

const CustomerAddressItem = ({ address }: { address: CustomerAddress }) => {
  const user = useLoggedInUser();

  const color = addressColorMap[address.tag as keyof typeof addressColorMap];

  return (
    <Box
      display='grid'
      gridTemplateColumns='350px 120px auto auto'
      alignItems='center'
      flex={1}
      flexWrap='wrap'
      border={`1px solid ${appColors.lightGray}`}
      p={3}
      mb={2}
    >
      <Typography style={{ marginRight: 16 }}>{constructAddress(address)}</Typography>
      <Box
        component='span'
        style={{
          textTransform: 'capitalize',
          fontWeight: 600,
          fontSize: 13,
          width: 100,
          height: 30,
          borderRadius: 5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 6,
          color: color.text,
          backgroundColor: color.bg,
          border: `1px solid ${color.border}`,
        }}
      >
        {address.tag === 'Home' ? (
          <RiHome4Fill color={color.text} fontSize={15} />
        ) : (
          <RiBuilding4Fill color={color.text} fontSize={15} />
        )}
        {address.tag}
      </Box>
      <Box justifySelf='flex-end'>
        {address.id === user?.shippingAddressId && (
          <Typography style={{ fontSize: 13, color: appColors.grayText }}>
            Default Shipping Address
          </Typography>
        )}
        {address.id === user?.billingAddressId && (
          <Typography style={{ fontSize: 13, color: appColors.grayText }}>
            Default Billing Address
          </Typography>
        )}
      </Box>
      <Link
        to={`/me/address/edit/${address.id}`}
        style={{
          justifySelf: 'flex-end',
          color: colors.primary,
          fontSize: 15,
          fontWeight: 600,
          marginRight: 16,
        }}
      >
        Edit
      </Link>
    </Box>
  );
};

export default CustomerAddressItem;
