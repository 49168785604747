import { useHistory } from 'react-router-dom';
import { Controller } from 'react-hook-form';

import Box from '@material-ui/core/Box';
import { Divider } from '@material-ui/core';

import { appColors } from '../../../utils/ui-utils';

import ListTitle from '../../common/ListTitle';
import FormButton from '../../common/FormButton';
import FormInput from '../../common/FormInput';
import FormSelect from '../../common/FormSelect';
import { useCountries } from '../hooks/useCountries';
import { getPlaceOptions } from '../../../utils/helpers';
import FormAutoComplete from '../../common/FormAutoComplete';
import AddressTypeField from './AddressTypeField';
import FormCheckbox from '../../common/FormCheckbox';
import { CustomerAddressInputs, useAddressForm } from './my-account-utils';
import { useGetSingleCustomerAddress } from '../hooks/useGetCustomerAddress';
import LoadingIndicator from '../../common/LoadingIndicator';
import useUpdateAddress from '../hooks/useUpdateAddress';
import { useAddressStyles } from './addressStyles';

const CustomerAddressEditForm = () => {
  const classes = useAddressStyles();
  const navigate = useHistory();
  const countries = useCountries();
  const addressMutation = useUpdateAddress();

  const addressToEdit = useGetSingleCustomerAddress();

  const form = useAddressForm({ initialValues: addressToEdit.data });

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = form;

  const { country, province } = watch();

  const { provinceOptions, cityOptions } = getPlaceOptions(country, province);

  const goBackToAddressList = () => navigate.push('/me/address');

  const onError = (err: typeof errors) => {
    console.log(err);
  };

  const onSubmit = (address: CustomerAddressInputs) => {
    if (isNaN(+address.postalCode)) {
      return setError('postalCode', { message: 'Invalid postal code' });
    }
    addressMutation.mutateAsync(address);
  };

  if (addressToEdit.isLoading) return <LoadingIndicator />;

  return (
    <Box
      flex={1}
      component='form'
      onSubmit={handleSubmit(onSubmit, onError)}
      style={{ pointerEvents: addressMutation.isLoading ? 'none' : 'initial' }}
      className={classes.form}
    >
      <ListTitle title='Edit Address' />
      <Box border={`1px solid ${appColors.mediumGray}`} mb={3}>
        <Box p={3} className={classes.formContainer}>
          <FormInput
            label='Address'
            placeholder='Please enter your address'
            {...register('addressLine')}
            error={!!errors?.addressLine}
            helperText={errors?.addressLine?.message}
          />
          <Controller
            control={control}
            name='country'
            render={({ field }) => (
              <FormSelect
                label='Country'
                options={countries}
                placeholder='Please select your country'
                name={field.name}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  setValue('province', '');
                  setValue('city', '');
                }}
                error={!!errors?.country}
                helperText={errors?.country?.message}
              />
            )}
          />
          <Controller
            control={control}
            name='province'
            render={({ field }) => (
              <FormAutoComplete
                label='Province'
                options={provinceOptions}
                placeholder='Please select your province'
                value={field.value}
                onChange={(e, v) => {
                  field.onChange(v === null ? '' : v);
                  setValue('city', '');
                }}
                error={!!errors?.province}
                helperText={errors?.province?.message}
                disabled={!country}
              />
            )}
          />
          <Controller
            control={control}
            name='city'
            render={({ field }) => (
              <FormAutoComplete
                label='City'
                options={cityOptions}
                placeholder='Please select your city'
                value={field.value}
                onChange={(e, v) => {
                  field.onChange(v === null ? '' : v);
                }}
                error={!!errors?.city}
                helperText={errors?.city?.message}
                disabled={!province}
              />
            )}
          />
          <FormInput
            label='Postal Code'
            placeholder='Please enter your postal code'
            {...register('postalCode')}
            error={!!errors?.postalCode}
            helperText={errors?.postalCode?.message}
          />
          <Controller
            control={control}
            name='tag'
            render={({ field }) => (
              <AddressTypeField value={field.value} onChange={field.onChange} />
            )}
          />
        </Box>
        <Divider style={{ backgroundColor: appColors.mediumGray }} />
        <Box p={3}>
          <Controller
            control={control}
            name='isDefaultShippingAddress'
            render={({ field }) => (
              <FormCheckbox
                label='Use as my default shipping address'
                name={field.name}
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            )}
          />
          <Controller
            control={control}
            name='isDefaultBillingAddress'
            render={({ field }) => (
              <FormCheckbox
                label='Use as my default billing address'
                name={field.name}
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            )}
          />
        </Box>
      </Box>
      <Box
        display='flex'
        flex={1}
        justifyContent='flex-end'
        style={{ gap: 20 }}
        className={classes.formButtons}
      >
        <FormButton onClick={goBackToAddressList} className='outline'>
          Cancel
        </FormButton>
        <FormButton type='submit'>
          {addressMutation.isLoading ? 'Saving...' : 'Save Changes'}
        </FormButton>
      </Box>
    </Box>
  );
};

export default CustomerAddressEditForm;
