import { RiUser3Line, RiMapPin3Line, RiPriceTagLine, RiTruckLine } from 'react-icons/ri';
import { useLocation } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import UserLink from './UserLink';
import { appColors } from '../../utils/ui-utils';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: 300,
      display: 'flex',
      flexDirection: 'column',
      gridRowGap: 28,
      backgroundColor: appColors.lightGray,
      padding: '32px 32px',
      marginBottom: 24,

      [theme.breakpoints.down('sm')]: {
        padding: '18px',
        width: '100%',
        boxSizing: 'border-box',
      },
    },
    title: {
      fontSize: 24,
      fontWeight: 600,

      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    linksContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',

      [theme.breakpoints.up('md')]: {
        display: 'block',

        '& > a': {
          marginBottom: 28,
        },
      },
    },
  })
);

const UserSideLinks = () => {
  const { pathname } = useLocation();

  const classes = useStyles();

  const accountLinksShown = ['/me/account', '/me/address'].some((i) => pathname.startsWith(i));

  return (
    <Box className={classes.container}>
      {accountLinksShown ? (
        <>
          <Typography className={classes.title}>My Account</Typography>
          <Box className={classes.linksContainer}>
            <UserLink to='/me/account'>
              <RiUser3Line /> My Profile
            </UserLink>
            <UserLink to='/me/address'>
              <RiMapPin3Line /> Address
            </UserLink>
          </Box>
        </>
      ) : (
        <>
          <Typography className={classes.title}>Orders</Typography>
          <Box className={classes.linksContainer}>
            <UserLink to='/me/orders'>
              <RiPriceTagLine /> My Orders
            </UserLink>
            <UserLink to='/me/track-orders'>
              <RiTruckLine /> Track Orders
            </UserLink>
          </Box>
        </>
      )}
    </Box>
  );
};

export default UserSideLinks;
