import { NavLink, NavLinkProps } from 'react-router-dom';

import { colors } from '../../theme/defaultTheme';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navLink: {
      textDecoration: 'none',
      color: colors.gray,
      fontSize: 18,
      transition: 'color 200ms',
      display: 'flex',
      alignItems: 'center',
      gap: 16,

      '&:not(.active):hover': {
        color: colors.darkest,
      },

      '&.active': {
        color: colors.primary,
      },

      '&.with-active': {
        color: colors.primary,
      },

      [theme.breakpoints.down('md')]: {
        fontSize: 14,
      },
    },
  })
);

const UserLink = (props: NavLinkProps) => {
  const classes = useStyles();

  return <NavLink {...props} className={classes.navLink} />;
};

export default UserLink;
