import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { RiHome4Fill, RiBuilding4Fill } from 'react-icons/ri';

import { CustomerAddress } from './types';
import { addressColorMap, constructAddress } from './my-account-utils';
import { appColors } from '../../../utils/ui-utils';
import { colors } from '../../../theme/defaultTheme';
import useLoggedInUser from '../hooks/useLoggedInUser';

const useAddressStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      border: `1px solid ${appColors.mediumGray}`,
      padding: 16,
      marginBottom: 16,
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    tag: {
      borderRadius: 4,

      '& > svg': {
        transform: 'scale(0.6)',
      },
      '&.home': {
        backgroundColor: addressColorMap.Home.bg,
        color: addressColorMap.Home.text,
        border: `1px solid ${addressColorMap.Home.border}`,
      },

      '&.office': {
        backgroundColor: addressColorMap.Office.bg,
        color: addressColorMap.Office.text,
        border: `1px solid ${addressColorMap.Office.border}`,
      },
    },
    editLink: {
      color: colors.primary,
      fontWeight: 'bold',
    },
    details: {
      marginTop: 18,
      display: 'flex',
      flexDirection: 'column',
      gap: 12,
    },
    name: {
      fontWeight: 'bold',
    },
  })
);

const AddressCard = ({ address }: { address: CustomerAddress }) => {
  const user = useLoggedInUser();
  const classes = useAddressStyles();

  const color = addressColorMap[address.tag as keyof typeof addressColorMap];

  const username = `${user?.firstName} ${user?.lastName}` || '';

  const contactNumber = user?.mobileNumber?.startsWith('+')
    ? user?.mobileNumber
    : `+${user?.mobileNumber}`;

  return (
    <Box className={classes.container}>
      <Box className={classes.row}>
        <Chip
          label={address.tag}
          className={[classes.tag, address.tag === 'Home' ? 'home' : 'office'].join(' ')}
          avatar={
            address.tag === 'Home' ? (
              <RiHome4Fill color={color.text} fontSize={8} />
            ) : (
              <RiBuilding4Fill color={color.text} fontSize={8} />
            )
          }
        />
        <Link to={`/me/address/edit/${address.id}`} className={classes.editLink}>
          Edit
        </Link>
      </Box>
      <Box className={classes.details}>
        <Typography className={classes.name}>{username}</Typography>
        <Typography>{contactNumber}</Typography>
        <Typography>{constructAddress(address)}</Typography>
        <Box>
          {address.id === user?.shippingAddressId && (
            <Typography style={{ fontSize: 13, color: appColors.grayText }}>
              Default Shipping Address
            </Typography>
          )}
          {address.id === user?.billingAddressId && (
            <Typography style={{ fontSize: 13, color: appColors.grayText }}>
              Default Billing Address
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AddressCard;
